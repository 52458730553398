import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';

import Hero from 'e84-website-styles/src/components/Hero';
import Callout from 'e84-website-styles/src/components/Callout';
import WonderLink from 'e84-website-styles/src/components/WonderLink';

import CubesatHero from 'assets/images/cubesat-hero-branded.png';
import VerticalListCard from 'e84-website-styles/src/components/VerticalListCard';
import image1 from '../assets/images/icons/GroundStations_Icon.svg';
import image2 from '../assets/images/icons/ArchiveImage_Icon.svg';
import image3 from '../assets/images/icons/ProcessingPipelines_Icon.svg';
import image4 from '../assets/images/icons/DiscoveryAccess_Icon.svg';

const cubesat = () => {

  const helmet_settings = {
    title: 'CubeSat Data - A Cube Satellite Data Processing Platform',
    bodyAttributes: {
      class: 'page-home',
    },
  };

  return (
    <Layout>
      <Helmet { ...helmet_settings } />

      <div className="site-section homepage-hero-wrapper">
        <div className="cubesat-blob animate-1"
          style={{
            backgroundImage: `url(${ CubesatHero })`,
          }}
        />

        <Hero modifierClass="homepage-hero">
          <h1 className="animate-2 marg-bot-1">
            CubeSatData.com is a hosted service for managing cube and small
            satellite data.
          </h1>
          <p className="animate-3">
            CubeSat Data can manage the entire data pipeline of your cube or
            small satellite mission -- from ground station downlink and data
            storage to automated archives of convenience so you can put your
            data to work solving problems.
          </p>
          <WonderLink className="button button-prim_gradient animate-4"
            to="/preview"
          >
            Let’s talk CubeSat!
          </WonderLink>
        </Hero>
      </div>

      <section className="site-section cubesat-callout-diagram">
        <div className="container">
          <div className="row">
            <div className="ten columns offset-by-one">
              <VerticalListCard title="Ground Stations and Data Acquisition"
                body="CubeSat Data can use existing S3 buckets as a data ingest source or automatically connect to your AWS Ground Station."
                image={ image1 }
              />
              <VerticalListCard title="Archive and Storage"
                body="Automatically store data based on planned use and let our FilmDrop engine help you build and index your metadata."
                image={ image2 }
              />
              <VerticalListCard title="Processing Pipelines"
                body="Generate ephemeral archives of convenience (AoC) and custom pipelines to make your data more useable."
                image={ image3 }
              />
              <VerticalListCard title="Discovery and Access"
                body="Search, discover, and visualize your data at scale and make it available via services for your team or customers."
                image={ image4 }
              />
              <span className="sr-only">CubeSat Data Infographic</span>
            </div>
          </div>
        </div>
      </section>

      <section className="site-section inverted-blue">
        <div className="container">
          <h2 className="text-center mini-heading">Additional Features</h2>
          <div className="row">
            <div className="six columns">
              <ul className="cubesat-features-list">
                <li>
                  Cloud-based, instantly scalable to meet data and processing
                  needs
                </li>
                <li>Custom data products and AoCs</li>
                <li>Algorithm development and execution</li>
                <li>Integration with 3rd party account management systems</li>
              </ul>
            </div>
            <div className="six columns">
              <ul className="cubesat-features-list">
                <li>Payment processing</li>
                <li>
                  Support for flexible sales and licensing models for data{ ' ' }
                </li>
                <li>Security and access auditing </li>
                <li>Backed by the power, security, and compliance of AWS</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Callout helperClass="text-center">
        <h2 className="marg-bot-3">Want to talk more about CubeSat Data?</h2>
        <WonderLink to="/preview"
          className="button button-prim_gradient button-lg"
        >
          Get in touch
        </WonderLink>
      </Callout>
    </Layout>
  );

};

export default cubesat;
